<template>
  <div>
    <!-- <this-header /> -->
    <div class="anan-set-nav-conttainer mt-150">
      <div class="row">
        <div class="col-md-2 col-lg-2 col-sm-12">
          <side-bar class="d-none d-md-block" />
        </div>
        <div class="col-md-10 col-lg-10 col-sm-12">
          <template>
            <div>
              <b-button
                class="btn btn-gradient-primary mb-1 mr-50 col-md-2.5"
                @click="$router.go(-1)"
              >
                <i class="fas fa-arrow-left mr-25" /> {{ $t('returnToList') }}
              </b-button>
              <b-row class="match-height">
                <b-col md="6">
                  <div class="card">
                    <div class="header-cardx p-2">
                      <h3 class="mb-0">
                        {{ $t('details') }}
                      </h3>
                    </div>

                    <div class="p-2">
                      <b-row>
                        <b-col md="12">
                          <b-form-group
                            label-for="h-paecelCode"
                            label-cols="4"
                            label-cols-lg="4"
                            label-cols-md="4"
                          >
                            <template v-slot:label>
                              {{ $t('orderNumber') }} :
                            </template>

                            <p class="mt-50 font-weight-bolder">
                              {{ items.itemnumber_text }}
                            </p>
                          </b-form-group>
                        </b-col>

                        <b-col
                          v-if="items.payment_type.type_id ==1"
                          md="12"
                        >
                          <b-form-group
                            label-for="h-paecelCode"
                            label-cols="4"
                            label-cols-lg="4"
                            label-cols-md="4"
                          >
                            <template v-slot:label>
                              {{ $t('key-63') }} :
                            </template>
                            <p class="mt-50 font-weight-bolder">
                              {{ items.payment_type.type_name }}
                            </p>
                          </b-form-group>
                        </b-col>
                        <b-col
                          v-if="items.payment_type.type_id !== 1"
                          md="12"
                        >
                          <b-form-group
                            label-for="h-paecelCode"
                            label-cols="4"
                            label-cols-lg="4"
                            label-cols-md="4"
                          >
                            <template v-slot:label>
                              {{ $t('taobaoID') }} :
                            </template>
                            <p class="mt-50 font-weight-bolder">
                              {{ items.payment_type.type_name }}
                            </p>
                          </b-form-group>
                        </b-col>

                        <b-col md="12">
                          <b-form-group
                            label-for="h-paecelCode"
                            label-cols="4"
                            label-cols-lg="4"
                            label-cols-md="4"
                          >
                            <template v-slot:label>
                              {{ $t('mnemonic') }} :
                            </template>
                            <p class="mt-50 font-weight-bolder">
                              <b-form-textarea
                                id="textarea-default"
                                v-model="items.keyword"
                                rows="3"
                                @change="UpdateData()"
                              />
                            </p>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                </b-col>

                <b-col md="6">
                  <div class="card">
                    <div class="header-cardx p-2">
                      <h3 class="mb-0">
                        {{ $t('relatedAmountList') }}
                      </h3>
                    </div>

                    <div class="p-2">
                      <b-row>
                        <b-col md="12">
                          <div class="pl-2 pr-2">
                            <div class="table-container">
                              <b-table
                                class="table-mobile w-100"
                                :fields="fieldsAmounts"
                                :items="Transactions"
                                show-empty
                              >
                                <template #empty="">
                                  <b-table-empty-slot>
                                    <div class="text-center my-2">
                                      <img
                                        src="@/assets/images/anan-img/svg/doc_empty.svg"
                                        alt="empty"
                                        height="60px"
                                      >
                                      <p class="text-muted">
                                        No Data
                                      </p>
                                    </div>
                                  </b-table-empty-slot>
                                </template>
                                <template #cell(created_at)="data">
                                  {{ time(data.item.created_at) }}
                                </template>
                                <template #cell(amount)="data">
                                  <!-- <span
                                  v-if="data.item.itemnumber_text == `BLA-CN-${data.item.itemnumber}`"
                                  :style="getAmountStyle(data.item.type)"
                                >
                                  {{ getAmountPrefix(data.item.type) }} {{ Commas(data.item.amount ) }} ¥
                                </span> -->
                                  <span
                                    :style="getAmountStyle(data.item.type)"
                                  >{{ getAmountPrefix(data.item.type) }} {{ Commas(data.item.amount) }} ฿</span>
                                </template>
                              </b-table>
                            </div>
                          </div>
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                </b-col>

                <b-col md="12">
                  <div class="card">
                    <div class="header-cardx p-2">
                      <h3 class="mb-0">
                        {{ $t('listProducts') }}
                      </h3>
                    </div>

                    <div class="pl-2 pr-2">
                      <b-table
                        responsive="sm"
                        :fields="fieldsRelevant"
                        :items="items.products"
                        show-empty
                      >
                        <template #empty="">
                          <b-table-empty-slot>
                            <div class="text-center my-2">
                              <img
                                src="@/assets/images/anan-img/svg/doc_empty.svg"
                                alt="empty"
                                height="60px"
                              >
                              <p class="text-muted">
                                No Data
                              </p>
                            </div>
                          </b-table-empty-slot>
                        </template>

                        <template #cell(number)="data">
                          {{ data.index + 1 }}
                        </template>
                        <template #cell(detail)="data">
                          {{ data.item.order_id }}
                        </template>
                        <template #cell(amount)="data">
                          ¥ {{ data.item.price }}
                        </template>
                        <template #cell(status)="data">
                          <span
                            v-if="data.item.status == 1"
                            class=" badge badge-light-primary "
                          >{{ $t('pendingReview') }}</span>

                          <span
                            v-if="data.item.status == 2"
                            class=" badge badge-light-success "
                          >{{ $t('operationCompleted') }}</span>
                          <span
                            v-if="data.item.status == 0"
                            class=" badge badge-light-secondary  text-black"
                            style="font-size: 12px;"
                          >{{ $t('cancelled') }}</span> 
                          <span
                            v-if="data.item.status == 0"
                            class="text-danger"
                          >* {{ data.item.remark_cancel ? `${data.item.remark_cancel} ` : '' }} </span>
                        </template>

                      </b-table>

                    </div>
                    <b-col
                      md="6"
                      class="ml-lg-auto p-2"
                    >
                      <div class="d-flex justify-content-around align-items-center p-1">
                        <div class="mr-auto">
                          {{ $t('yuanAmount') }} {{ Commas(items.products.reduce((total, acc) => total + acc.price, 0)) }} x {{ Commas(items.rate) }}
                        </div>
                        <div> {{ Commas(items.products.reduce((total, acc) => total + acc.price, 0) * items.rate) }} ฿</div>
                      </div>

                      <div class="d-flex justify-content-around align-items-center p-1">
                        <div class="mr-auto">
                          {{ $t('key-65') }} ( {{ items.products.length }} x {{ items.rate_service }} )
                        </div>
                        <div>  {{ items.products.length * Number(items.rate_service) }} ฿</div>
                      </div>
                      <div class="d-flex justify-content-around align-items-center p-1">
                        <div class="mr-auto">
                          {{ $t('key-66') }}
                        </div>
                        <div>   {{ Commas(items.pay_products) }} ฿</div>
                      </div>

                      <!-- <b-list-group horizontal="sm">
                        <b-list-group-item style="width: 70%">
                          {{ $t('yuanAmount') }} {{ Commas(items.products.reduce((total,acc) => total+ acc.price,0)) }} x {{ Commas(items.rate) }}
                        </b-list-group-item>
                        <b-list-group-item style="width: 30%">
                          {{ Commas(items.products.reduce((total,acc) => total+ acc.price,0)*items.rate) }} ฿
                        </b-list-group-item>
                      </b-list-group>

                      <b-list-group horizontal="sm">
                        <b-list-group-item style="width: 70%">
                          {{ $t('key-65') }} ( {{ items.products.length }} x {{ items.rate_service }} )
                        </b-list-group-item>
                        <b-list-group-item style="width: 30%">
                          {{ items.products.length * Number(items.rate_service) }} ฿
                        </b-list-group-item>
                      </b-list-group>
                      <b-list-group horizontal="sm">
                        <b-list-group-item style="width: 70%">
                          {{ $t('key-66') }}
                        </b-list-group-item>
                        <b-list-group-item style="width: 30%">
                          {{ Commas(items.pay_products) }} ฿
                        </b-list-group-item>
                      </b-list-group> -->
                    </b-col>
                  </div>
                </b-col>
              </b-row>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  BButton,
  BCol,
  BFormGroup,
  BFormTextarea,
  BListGroup,
  BListGroupItem,
  BRow,
  BTable,
} from 'bootstrap-vue'
// import ThisHeader from '@/views/customer/component/ThisHeader.vue'
import moment from 'moment-timezone'
import SideBar from '../component/SideBar.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BTable,
    SideBar,
    // ThisHeader,
    BFormTextarea,
    BListGroup,
    BListGroupItem,
  },
  data() {
    return {
      isActive: true,
      file: null,
      parcelCode: null,
      remark: null,
      Transaction: [],

      ID: this.$route.params.id,
      listOfAmounts: [],
      items: [],
      Transactions: [],
    }
  },
  computed: {
    fieldsRelevant() {
      return [
        {
          key: 'number',
          label: this.$t('sequence'),
          thStyle: { width: '10%' },
        },
        {
          key: 'detail', label: this.$t('orderList'), thStyle: { width: '50%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'amount',
          label: this.$t('quantity'),
          thStyle: { width: '15%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'status',
          label: this.$t('status'),
          thStyle: { width: '20%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ]
    },
    fieldsAmounts() {
      return [
        { key: 'created_at', label: this.$t('transactionDate'), thStyle: { width: '30%' } },
        {
          key: 'detail',
          label: this.$t('details'),
          thStyle: { width: '50%' },
        },
        {
          key: 'amount',
          label: this.$t('quantity'),
          thStyle: { width: '20%' },
        },
      ]
    },
  },
  mounted() {
    this.getData()
    this.getTransactions()
  },
  methods: {
    time(data) {
      return moment(data).format('YYYY-MM-DD HH:mm:ss')
    },
    async getData() {
      try {
        const params = {
          ID: this.ID,
        }
        const { data: res } = await this.$http.get('/Payforchina/getPaymentOne', { params })
        this.items = res
        // console.log(res)
      } catch (err) {
        console.log(err)
      }
    },
    async getTransactions() {
      try {
        const params = {
          ref_id: this.ID,
        }
        const { data: res } = await this.$http.get('/transactions/show/ref', { params })

        if (res.success) {
          this.Transactions = res.data
          // this.getTransactionsChina(res.data)
        }
      } catch (err) {
        console.log(err)
        this.SwalError(err)
      }
    },
    // getTransactionsChina(data) {
    //   try {
    //     const params = {
    //       ref_id: this.ID,
    //     }
    //     this.$http.get('/transactions/show/refChina', { params })
    //       .then(success => {
    //         if (success.data.success) {
    //           this.Transactions = data
    //           success.data.data.forEach(ele => {
    //             this.Transactions.push(ele)
    //           })
    //         } else {
    //           this.Transactions = data
    //         }
    //       })
    //     // console.log(res)
    //     // if (res.success) {
    //     //   res.data.forEach(ele => {
    //     //     this.Transactions.push(ele)
    //     //     // console.log(ele)
    //     //   })
    //     //   console.log(this.Transactions)
    //     //           this.Transactions.push(res.data)
    //     // console.log(this.Transactions)
    //     // }
    //     // Payforchina/Update
    //   } catch (err) {
    //     console.log(err)
    //     this.SwalError(err)
    //   }
    // },
    UpdateData() {
      const obj = {
        ID: this.ID,
        keyword: this.items.keyword,
      }
      this.$http.post('/Payforchina/Update', obj)
        .then(() => {
          this.Success('บันทึกข้อมูลเรียบร้อย')
          this.getData()
        })
        .catch(err => {
          console.log(err)
          this.SwalError(err)
        })
    },
    getAmountStyle(amount) {
      if (amount) {
        if (amount && [3, 4, 5, 6, 7, 13, 15, 16, 18, 10, 9].includes(amount)) {
          return { color: 'red' }
        }
        if (amount && [8, 11, 12, 17].includes(amount)) {
          return { color: 'green' }
        }
      }
      return { color: 'orange' }
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: `<h3 style="color: #141414">${this.$t('key-298')}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
    getAmountPrefix(value) {
      if (value) {
        if (value && [3, 4, 5, 6, 7, 13, 15, 16, 18, 10, 9].includes(value)) {
          return '-'
        }
        if (value && [8, 11, 12, 17].includes(value)) {
          return '+'
        }
      }
      return ''
    },
  },
}
</script>

<style lang="scss">
@media only screen and (max-width: 600px) {

  .table-mobile {
    width: 600px !important;
  }
}
</style>
